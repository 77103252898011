import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("@/views/auth/Login.vue"),
    meta: { layout: "auth", auth: false }
  },
  {
    path: "/login",
    redirect: "/"
  },
  {
    path: "/dashboard",
    component: () => import("@/components/Layout/Dashboard.vue"),
    meta: { auth: true, layout: "dashboard" },
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("@/views/dashboard/Index.vue")
      },
      {
        path: "/operators",
        name: "operators",
        component: () => import("@/views/opsMgt/operators/Index.vue")
      },
      {
        path: "/operator/:id",
        name: "operatorDetails",
        component: () => import("@/views/opsMgt/operators/Details.vue")
      },
      {
        path: "/driver_broadcasts",
        name: "driver-broadcast",
        component: () => import("@/views/opsMgt/broadcasts/drivers/Index.vue")
      },
      {
        path: "/commuter_broadcasts",
        name: "commuter-broadcast",
        component: () => import("@/views/opsMgt/broadcasts/commuters/Index.vue")
      },
      {
        path: "/pricing",
        name: "pricing",
        component: () => import("@/views/opsMgt/pricing/Index.vue")
      },
      {
        path: "/drivers",
        name: "drivers",
        component: () => import("@/views/opsMgt/drivers/Index.vue")
      },
      {
        path: "/drivers/:driverAccountId?",
        name: "driverDetails",
        component: () => import("@/views/opsMgt/drivers/Details.vue")
      },
      {
        path: "/driver_requests",
        name: "driverRequests",
        component: () => import("@/views/opsMgt/drivers/requests/Index.vue")
      },
      {
        path: "/vehicles",
        name: "vehicles",
        component: () => import("@/views/opsMgt/vehicles/Index.vue")
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/usersMgt/Profile.vue")
      },
      {
        path: "/organizations",
        name: "organizations",
        component: () => import("@/views/opsMgt/organizations/Index.vue")
      },
      {
        path: "/organizations/:id",
        name: "organizationDetails",
        component: () => import("@/views/opsMgt/organizations/Details.vue")
      },
      {
        path: "/organization_requests",
        name: "organizationRequests",
        component: () =>
          import("@/views/opsMgt/organizations/requests/Index.vue")
      },
      {
        path: "/commuters",
        name: "commuters",
        component: () => import("@/views/opsMgt/commuters/Index.vue")
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/usersMgt/Index.vue")
      },
      {
        path: "/users/:id",
        name: "userDetails",
        component: () => import("@/views/usersMgt/Details.vue")
      },
      {
        path: "/users/:id/account-management/:account_type",
        name: "userAccountManagement",
        component: () => import("@/views/usersMgt/Accounts.vue")
      },
      {
        path: "/loops",
        name: "loops",
        component: () => import("@/views/loopsMgt/loops/Index.vue")
      },
      {
        path: "/loops/:id",
        name: "loopDetails",
        component: () => import("@/views/loopsMgt/loops/Details.vue")
      },
      {
        path: "/loops/:loopId/schedule-trips/:scheduleId",
        name: "scheduleTrips",
        component: () => import("@/views/loopsMgt/schedules/Index.vue")
      },
      {
        path: "/charters",
        name: "charters",
        component: () => import("@/views/loopsMgt/charters/Index.vue")
      },
      {
        path: "/day-hires",
        name: "dayHires",
        component: () => import("@/views/loopsMgt/day-hires/Index.vue")
      },
      {
        path: "/trips",
        name: "trips",
        component: () => import("@/views/loopsMgt/trips/Index.vue")
      },
      {
        path: "/trips/:id",
        name: "tripsDetails",
        component: () => import("@/views/loopsMgt/trips/Details.vue")
      },
      {
        path: "/deliveries",
        name: "deliveries",
        component: () => import("@/views/deliveriesMgt/deliveries")
      },
      {
        path: "/delivery/:id",
        name: "deliveryDetails",
        component: () => import("@/views/deliveriesMgt/deliveries/Details.vue")
      },
      {
        path: "/bookings",
        name: "bookings",
        component: () => import("@/views/loopsMgt/bookings/Index.vue")
      },
      {
        path: "/reports",
        name: "reports",
        component: () => import("@/views/reports/Reports.vue")
      },
      {
        path: "/reports/nps",
        name: "nps",
        component: () => import("@/views/reports/Nps.vue")
      },
      {
        path: "/payments",
        name: "payments",
        component: () => import("@/views/paymentsMgt/payments")
      },
      {
        path: "/payments/:id",
        name: "paymentDetails",
        component: () => import("@/views/paymentsMgt/payments/Details.vue")
      },
      {
        path: "/invoices",
        name: "invoices",
        component: () => import("@/views/paymentsMgt/invoices")
      },
      {
        path: "/invoices/:id",
        name: "invoiceDetails",
        component: () => import("@/views/paymentsMgt/invoices/Details.vue")
      },
      {
        path: "/deliveries/accounts",
        name: "deliveriesAccount",
        component: () => import("@/views/deliveriesMgt/accounts/Index.vue")
      }
    ]
  }
];

Vue.router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default Vue.router;
