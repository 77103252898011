import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common.source = "command center";

axios.interceptors.response.use(
  response => {
    Object.assign(response, response.data);
    return response;
  },
  function (e) {
    let title = "";
    let text = "something weird happened, please try again";
    let icon = "warning";
    const { response } = e;

    if (
      response.data.error &&
      Object.prototype.hasOwnProperty.call(response.data.error, "errors") &&
      Object.keys(response.data.error.errors).length > 0
    ) {
      const errorList = response.data.error.errors.filter(Boolean);
      text = "";

      // Check if the error list is an object (associative array)
      if (typeof errorList === "object" && !Array.isArray(errorList)) {
        // Iterate over the keys and values of the object (associative array)
        for (const key in errorList) {
          errorList[key].forEach(element => {
            text += "<li>" + element + "</li>";
          });
        }
      }
      // Check if the error list is an array (indexed array)
      else if (Array.isArray(errorList)) {
        // Iterate over the elements of the array (indexed array)
        errorList.forEach(element => {
          text += "<li>" + element + "</li>";
        });
      } else if (response.data.error) {
        if (response.data.error.message) {
          text = response.data.error.message;
        } else {
          text = response.data.error;
        }
      } else {
        text = response.data.message;
      }

      if (response?.data?.error?.message) {
        title = response.data.error.message;
      }

      switch (response.status) {
        case 401:
          title = "Unauthorized Access";
          break;
        case 403:
          title = "Forbidden";
          break;
        case 422:
          title = "Validation error(s)";
          icon = "error";
          break;
        case 405:
          title = "Not allowed";
          break;
        default:
          title = "Something went wrong";
          icon = "error";
          break;
      }

      Vue.prototype.$swal({
        icon,
        title,
        html: text,
        allowOutsideClick: false,
        confirmButtonText: "OKAY"
      });
      return Promise.reject(e);
    }
  }
);

Vue.use(VueAxios, axios);
